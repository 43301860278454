<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./assets/css/global.css";
/*脚部的样式*/
.el-footer {
  text-align: center;
  height: 50px !important;
  /* background-color: white; */
  opacity: 0.6;
}
</style>
